import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import {
  District,
  NewGoalState,
  Plan,
  School,
  UserRole,
  userRoles,
} from "../../../../types";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import * as _ from "lodash";
import { useIntl } from "react-intl";
import useRolesPermissions from "../../../../helpers/hooks/useRolesPermissions";
import { FormatOptionLabelMeta } from "react-select/src/Select";
import { generatePath, useHistory, useLocation } from "react-router";
import { AuthRoutes } from "../../../../App";
import {
  hideConfirmDialog,
  showConfirmDialog,
} from "../../../../store/UIState/actions";
import { changeActivePlan } from "../../../../store/plans/actions";
import { resetNewGoalState } from "../../../../store/goals/actions";
import { isDistrictPlan } from "../../../../store/plans/selectors";
import { LocalStorageKeys } from "../../../../constants";
import useUserRole from "../../../../helpers/hooks/useUserRole";
import { fabClasses } from "@mui/material";

type OwnProps = {
  activePlan?: Plan;
};

type Props = OwnProps;

const MenuFilter: FunctionComponent<Props> = (props) => {
  const { activePlan } = props;
  const intl = useIntl();

  const isSuperAdmins = useRolesPermissions(userRoles.admins as UserRole[]);

  const location = useLocation();

  const history = useHistory();
  const dispatch = useDispatch();
  const { isDCC, teamRole, isSuperintendent } = useUserRole();
  const { setupCompleted } = useSelector((s) => s.tours);

  const CurrentUser: any = localStorage.getItem(LocalStorageKeys.CurrentUser);
  const CurrentUserEmail = JSON.parse(CurrentUser).email;

  const plans = useSelector((s) => s.plans.plans);
  const schools: School[] = useSelector((s) => s.schools.schools as School[]);
  const schoolPrincipal = schools.find(
    (s) => s.principal?.email === CurrentUserEmail
  );

  useEffect(() => {
    let selectedPlan;
    if (schoolPrincipal && isSuperintendent && !setupCompleted) {
      selectedPlan = plans.find((p) => p?.school?.id === schoolPrincipal?.id);
      selectedPlan && dispatch(changeActivePlan(selectedPlan!));
    }
  }, [schoolPrincipal, plans]);
  const welcomePagePath = useMemo(
    () =>
      generatePath(AuthRoutes.AppWelcomePage, {
        workspace: "welcome",
      } as any),
    []
  );

  const isAppWelcomePage = useMemo(
    () => location.pathname === welcomePagePath,
    [location, welcomePagePath]
  );

  const districts = useMemo(
    () =>
      _.uniqBy(
        plans.map((p) => p.district),
        (d) => d.id
      ),
    [plans]
  );

  const [selectedDistrict, setSelectedDistrict] = useState<
    District | undefined
  >();
  useEffect(() => {
    setSelectedDistrict(activePlan?.district);
  }, [activePlan?.district]);

  const [displayedPlans, setDisplayedPlans] = useState<Plan[] | any>([]);

  function sortPlans(a: Plan, b: Plan) {
    return a?.school?.name > b?.school?.name
      ? 1
      : b?.school?.name > a?.school?.name
      ? -1
      : 0;
  }

  useEffect(() => {
    let sortedPlans = plans?.filter(
      (p) => p.district.id === selectedDistrict?.id
    );
    sortedPlans = sortedPlans.sort(sortPlans);

    let sortedPlanDistrictIndex = sortedPlans.findIndex((sp) => !sp.school.id);

    let sortedPlanSchools = sortedPlans.filter((sp) => sp.school.id);
    setDisplayedPlans(() => {
      const safeSortedPlans = sortedPlans || []; // Fallback to an empty array if sortedPlans is undefined
      const safeSortedPlanDistrict = safeSortedPlans[sortedPlanDistrictIndex] || {
        school: {
          id: "no-data",
          name: "No District Available",
        },
        isDisabled: true,
      };
    
      return [
        safeSortedPlanDistrict,
        {
          school: {
            id: "disable",
            name: "Select School",
          },
          isDisabled: true,
        },
        ...sortedPlanSchools,
      ];
    });
  }, [plans, selectedDistrict]);

  useEffect(() => {
    districts.sort((a: any, b: any) => {
      return b?.is_dummy_district - a?.is_dummy_district;
    });
  }, [plans]);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  // autosave goal - start
  const { goalStatement, selectedGoal } = useSelector(
    (s) => s.goals.newGoalState
  );
  const { goal } = useSelector((s): NewGoalState => s.goals.newGoalState);

  const [isNewGoal, setNewGoal] = useState<boolean>(false);
  const lastGoal = useSelector((s) => s.goals.goals.slice(-1)[0]);

  const updatedSelectedGoal = useSelector((s) =>
    s.goals.goals.filter((s) => s.id === selectedGoal?.id)
  );

  // useEffect(() => {
  //   console.log('Uppppp sasaddgfhj')

  //   dispatch(
  //     updateNewGoalState({
  //       show: true,
  //       selectedGoal: updatedSelectedGoal[0],
  //     })
  //   );
  // }, [updatedSelectedGoal[0]]);

  const activePlanId = useSelector((s) => s.plans.activePlan?.id);
  const selectedPriority = useSelector((s) => s.goals.selectedPriority);
  const selectedPriorityIds = useMemo(() => {
    let priorityIds = selectedPriority.map((s) => s.id);

    return priorityIds;
  }, [selectedPriority]);
  // autosave goal - end

  const handlePlanItemChange = (planId?: number) => {
    let selectedPlan;
    if (planId) {
      selectedPlan = plans.find((p) => p.id === planId);
    } else {
      selectedPlan = plans.find(
        (p) => p.district.id === selectedDistrict?.id && !p.school.id
      );
    }

    if (
      window.location.pathname.includes("plan/goals") &&
      selectedPriorityIds.length > 0
    ) {
      showWarningDialog(selectedPlan!);
    } else {
      dispatch(changeActivePlan(selectedPlan!));
      history.push(
        generatePath(AuthRoutes.YourPlan, {
          workspace: "dashboard",
        } as any)
      );
    }
  };

  // save goal - start
  // const saveGoal = () => {
  //   const request: AddGoalRequest = {
  //     is_active: false,
  //     planId: activePlanId!,
  //     statement: goalStatement ? goalStatement : "",
  //     targets: [],
  //     desired_outcome: "",
  //     priority_areas: selectedPriorityIds,
  //     is_completed: false,
  //   };

  //   if (selectedGoal) {
  //     selectedGoal.targets.forEach((el) => {
  //       request.targets?.push({
  //         direction: el.direction,
  //         target_date: el.target_date,
  //         target_value: el.target_value,
  //         domain_priority: el.domain_priority,
  //         dataset: el.dataset,
  //       });
  //     });

  //     dispatch(updateGoal.request({ ...request, goalId: selectedGoal.id }));
  //   } else {
  //     dispatch(createGoal.request(request));
  //     setNewGoal(true);
  //   }
  // };

  const showWarningDialog = (selectedPlan: Plan<District, School<number>>) => {
    dispatch(
      showConfirmDialog({
        onConfirm: () => {
          // saveGoal();
          setTimeout(() => {
            dispatch(resetNewGoalState());
            dispatch(hideConfirmDialog());

            const selectedDistrict = districts.find(
              (d) => d.id === selectedPlan.district.id
            );
            if (selectedDistrict) {
              setSelectedDistrict(selectedDistrict);
              setDisplayedPlans(
                plans.filter((p) => p.district?.id === selectedDistrict.id)
              );
            }

            dispatch(changeActivePlan(selectedPlan!));
            history.push(
              generatePath(AuthRoutes.YourPlan, {
                workspace: "dashboard",
              } as any)
            );
          }, 200);
        },
        show: true,
        intent: "warning",
        icon: "info-sign",
        text: intl.formatMessage(
          {
            id: "app.goals.new-goal.warning-dialogs",
          },
          { i: (chunks) => <i>{chunks}</i>, br: <br /> }
        ),
        confirmButtonText: intl.formatMessage({
          id: "app.titles.save-and-continue-later",
        }),
        cancelButtonText: intl.formatMessage({
          id: "app.titles.cancel",
        }),
        onCancel: () => {
          dispatch(hideConfirmDialog());
        },
        canOutsideClickCancel: true,
      })
    );
  };
  // save goal - end

  const handleDistrictChange = (districtId?: number) => {
    const selectedDistrict = districts.find((d) => d.id === districtId);
    if (selectedDistrict) {
      const firstPlanWithSelectedDistrict = plans.find(
        (p) => p.district.id === districtId && !p.school.id
      );
      if (
        window.location.pathname.includes("plan/goals") &&
        selectedPriorityIds.length > 0
      ) {
        showWarningDialog(firstPlanWithSelectedDistrict!);
      } else {
        setSelectedDistrict(selectedDistrict);
        dispatch(changeActivePlan(firstPlanWithSelectedDistrict!));
      }
    }
  };

  const schoolPanelItem = (
    plan?: Plan,
    context?: FormatOptionLabelMeta<Plan, false>
  ) => {
    const isDistrictPlan = !plan?.school?.id;
    const isMenuOptionSelected =
      plan?.id === activePlan?.id && context?.context === "menu";
    const selectedOptionColor = isMenuOptionSelected
      ? "text-white"
      : "text-blue-600";
    return (
      <>
        {" "}
        <div>
          {isDistrictPlan
            ? plan?.district?.name
            : plan?.school?.name === "Select School" && plan?.school?.name}
        </div>
        <h3>
          {!isDistrictPlan &&
            plan?.school?.name !== "Select School" &&
            plan?.school?.name}
        </h3>
      </>
    );
  };

  useEffect(() => {
    dispatch(resetNewGoalState());
  }, [activePlan, dispatch]);

  if (plans.length > 1) {
    return (
      <div className="site-navigation_filter">
        <label>
          {isDistrictActivePlan
            ? intl.formatMessage({
                id: "app.district-site.label",
              })
            : intl.formatMessage({
                id: "app.school-site.label",
              })}
        </label>
        <div className="select-plan">
          {isSuperAdmins && (
            <Select
              value={selectedDistrict}
              options={districts}
              getOptionValue={(district) => String(district.id)}
              getOptionLabel={(district) => district.name}
              onChange={(selectedDistrict) =>
                handleDistrictChange(selectedDistrict?.id)
              }
              isDisabled={isAppWelcomePage}
            />
          )}
        </div>
        <div data-cy="school-panel-selector-container" className="select-plan">
          {/* {console.log(displayedPlans,"<<<displayedPlans",activePlan,"<<activePlan")} */}
          <Select
            value={activePlan}
            options={displayedPlans}
            placeholder={intl.formatMessage({
              id: "app.titles.select-school-plan",
            })}
            getOptionValue={(plan) => plan.id as any}
            getOptionLabel={(plan) =>
              plan?.school?.name || plan?.district?.name
            }
            formatOptionLabel={(p, context) => schoolPanelItem(p, context)}
            onChange={(newItem) => handlePlanItemChange(newItem?.id)}
            styles={{
              singleValue: (styles) => ({
                ...styles,
                top: "unset",
                transform: "unset",
                position: "initial",
                padding: "0.125rem",
                flex: 1,
              }),
              menu: (styles) => ({ ...styles, zIndex: 10 }),
            }}
            isDisabled={isAppWelcomePage}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="site-navigation_filter">
        <label>
          {isDistrictActivePlan
            ? intl.formatMessage({
                id: "app.district-site.label",
              })
            : intl.formatMessage({
                id: "app.school-site.label",
              })}
        </label>
      </div>
      {schoolPanelItem(activePlan)}
    </>
  );
};

export default MenuFilter;
