import React, { useState } from "react";
import { Dialog, DialogActions, DialogContent, IconButton } from "@material-ui/core";
import { Button, Checkbox, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useIntl } from "react-intl";
 
 
 
 
 
 
type OwnProps = {
    setOpenGenerateReportDialog:any;
    openGenerateReportDialog:any;
    onSurveyDeploymentCloseConfirmed:any;
    
  };
  
  type Props = OwnProps;
 
  
 
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
      padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
      padding: theme.spacing(1)
    }, "& .MuiPaper-root": {
      width: "40%", // Set the desired width here
      maxWidth: "100%", // Adjust max-width to make it responsive
    },
  }));
 
 
 
 
 
 
const  GenerateReportDialog:React.FC<Props>=(props)=> {
 
    const {
      setOpenGenerateReportDialog,
      openGenerateReportDialog,
      onSurveyDeploymentCloseConfirmed,
    } = props;
 
    const intl = useIntl();
    const [generateReportCheckbox1,setGenerateReportCheckbox1]=useState<boolean>(false);
    const [generateReportCheckbox2,setGenerateReportCheckbox2]=useState<boolean>(false);
 
    const handleClose = () => {
      setOpenGenerateReportDialog({ open: false, surveyDeploymentId: null });
    };
 
    const handlegenerateReport = () => {
      onSurveyDeploymentCloseConfirmed(
        openGenerateReportDialog?.surveyDeploymentId
      );
      handleClose();
    };
const onCheckFirstCondition = (value: boolean) => {
  setGenerateReportCheckbox1(!value);
};
const onCheckSecondCondition = (value: boolean) => {
  setGenerateReportCheckbox2(!value);
};
 
 
 
 
  return (
    <div className="teampage_container data-collection-report">
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openGenerateReportDialog?.open}
        maxWidth="sm"
      >
        <span style={{ display: "flex", justifyContent: "flex-end" }}>
          <CloseIcon onClick={handleClose} />
        </span>
        <div className="information-modal" style={{ paddingTop: "0px" }}>
          <DialogContent
            className="content-padding"
            style={{ paddingTop: "0px" }}
          >
 
 
 
            <div className="info-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="28"
                viewBox="0 0 9 28"
                fill="none"
              >
                <path
                  d="M2.97149 8.51903H5.99549V25.151H2.97149V8.51903Z"
                  fill="#195A94"
                />
                <circle cx="4.72021" cy="3.44565" r="2.68799" fill="#195A94" />
              </svg>
            </div>
            <p className="text-left">
              {intl.formatMessage(
                { id: "app.confirmation-dialogs.close-survey-deployment.new" },
                {
                  br: <br />,
                  b: (chunks) => <b>{chunks}</b>,
                  i: (chunks) => <i>{chunks}</i>,
                  checkbox1: (chunks) => {
                    return (
                      <>
                        <Checkbox
                          checked={generateReportCheckbox1}
                          onClick={() =>
                            onCheckFirstCondition(generateReportCheckbox1)
                          }
                        />
                        {chunks}
                      </>
                    );
                  },
                  checkbox2: (chunks) => {
                    return (
                      <>
                        <Checkbox
                          checked={generateReportCheckbox2}
                          onClick={() =>
                            onCheckSecondCondition(generateReportCheckbox2)
                          }
                        />
                        {chunks}
                      </>
                    );
                  },
                }
              )}
 
            </p>
            
          </DialogContent>
          <DialogActions style={{ display: "flex", justifyContent: "center" }}>
            <Button
              autoFocus
              className="btn btn-secondary"
              onClick={handleClose}
            >
              {intl.formatMessage({ id: "app.titles.cancel" })}
            </Button>
            <Button
              autoFocus
              className="btn btn-primary"
              onClick={handlegenerateReport}
              disabled={!(generateReportCheckbox1 && generateReportCheckbox2)}
            >
             <p className={!(generateReportCheckbox1 && generateReportCheckbox2)?"text-gray-500":"text-white"}>
               {intl
                .formatMessage({
                  id:
                    "app.survey-deployments.close-confirm.generate-reports.new",
                })
                .toUpperCase()}
              </p>
            </Button>
          </DialogActions>
        </div>
      </BootstrapDialog>
    </div>
  );
}
 
export default GenerateReportDialog;