import React, { FunctionComponent } from "react";
import { Icon } from "@blueprintjs/core";

const ICON_SIZE = 30;

const CustomArrow: FunctionComponent<any> = props => {
  return (
    <Icon
      iconSize={ICON_SIZE}
      icon={props.isPrev ? "chevron-left" : "chevron-right"}
      className={`${props?.disabled?"text-gray-500  hover:text-gray-500 cursor-not-allowed" :"text-black  hover:text-blue-600 cursor-pointer"} absolute border-none`}
      style={{ [props.isPrev ? "left" : "right"]: "-10px", bottom: "50%" ,zIndex:10}}
      onClick={props?.disabled?null:props.onClick}
    />
  );
};

export default CustomArrow;