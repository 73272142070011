import { Button, Callout, Spinner } from "@blueprintjs/core";
import moment from "moment";
// import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";

import { Helmet } from "react-helmet";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getMeetings } from "../../../store/meetings/actions";
import { isDistrictPlan } from "../../../store/plans/selectors";
import { getSurveysDeployment } from "../../../store/surveys-deployment/actions";
import DashboardWidget from "../dashboard-widget/DashboardWidget";
import TeamPlanWelcome from "../TeamPlanWelcome";
import StrategicPlanWidget from "./strategic-plan-widget/StrategicPlanWidget";
import SurveyAdministrationWidget from "./survey-administration-widget/SurveyAdministrationWidget";
import UpcomingEventsCard from "./UpcomingEventsCard";
import RecentSCIWritingWidget from "./recent-sci-writing-widget/RecentSCIWritingWidget";
import image from "../../../static/icons/png/SeeYouSoon.png";
import { generatePath } from "react-router";
import { AuthRoutes } from "../../../App";
import { Meeting, PlanWorkspaces, UserNotesType } from "../../../types";
import { useHistory } from "react-router-dom";
import DistrictSetupWidget from "./district-setup-widget/DistrictSetupWidget";
import useWorkspacesLock from "../../../helpers/hooks/useWorkspacesLock";
import DashboardTour from "../../tour/team-plan/DashboardTour";
import WorkspaceHeader from "../workspace-header/WorkspaceHeader";
import evntImg from "../../../static/icons/svg/event-cal.svg";
import { useLoading } from "../../../helpers/hooks/useLoading";
import UpcomingActivityTag from "./upcoming-events/UpcomingActivityTag";
import { useUpcomingActivityTag } from "../../../helpers/hooks/useUpcomingActivityTag";

type OwnProps = {
  title?: string;
};

type Props = OwnProps;
const NUMBER_OF_MONTHS = 2;
const MAX_ITEMS = 6;

const TeamPlanDashboard: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const meetings = useSelector((s) => s.meetings.meetings);
  const loadings = useSelector((s) => s.meetings.loading.getMeetings);
  const error = useSelector((s) => s.meetings.errors.getMeetings);

  const { getTagButton, getTagBackgroundColor } = useUpcomingActivityTag();
  
  const dispatch = useDispatch();
  const [selectedMeeting, setSelectedMeeting] = useState(null);


  const history = useHistory();

  const activePlan = useSelector((s) => s.plans.activePlan);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const activeSurveysDeployment = useSelector((s) =>
    s.surveysDeployment.surveysDeployment
  // ?.filter((sd) =>
  //     moment(sd.end_date).isAfter(moment())
    // )
  );

  const { wsAvailableDict } = useWorkspacesLock({ workspaces: [] });

  const members = useSelector((s) => s.teamMembers.teamMembers);
  const loading = useSelector(
    (s) => s.teamMembers.loading.getTeamMembersByPlan
  );
  useLoading({ loading, error });
  const [showStrategicPlan, setShowStrategicPlan] = useState(false);

  // const showNoMembersCallout = useMemo(
  //   () => {
  //     return false; // TODO 10/11/2021 Hide for now: onboarding updates will replace it

  //     // return (
  //     //   isDistrictActivePlan &&
  //     //   members?.length &&
  //     //   members.length === 1 &&
  //     //   !loading
  //     // );
  //   },
  //   [
  //     // members, loading, isDistrictActivePlan
  //   ]
  // );
  const me = useSelector((s) => s.auth.authenticatedUser);

  const getPlansLoading = useSelector((s) => s.plans.loading.getPlans);

  useEffect(() => {
    if (activePlan) {
      dispatch(getSurveysDeployment.request({ planId: activePlan?.id }));
    }
  }, [activePlan, dispatch]);

  useEffect(() => {
    if (activePlan) {
      dispatch(getMeetings.request(activePlan.id));
    }
  }, [activePlan,dispatch]);

  const handleNavigateClick = () => {
    history.push(
      generatePath(AuthRoutes.YourPlanTeam, {
        workspace: "team",
        tabId: isDistrictActivePlan
          ? UserNotesType.DCLT_TEAM_HOME
          : UserNotesType.SCLT_TEAM_HOME,
      } as any)
    );
  };

  useEffect(() => {
    const isDummyDistrict = me?.profile?.district?.is_dummy_district;
    if (isDummyDistrict){
      setShowStrategicPlan(true);
    }
    else{
      setShowStrategicPlan(false);
    }
  }, [activePlan, me]);

  const handleMoveToCollectDataClick = () => {
    history.push(
      generatePath(AuthRoutes.YourPlanData, {
        workspace: "data",
        tabId: UserNotesType.DC_REPORTING_SURVEY_DEPLOYMENTS,
      } as any)
    );
  };

  const showNoMembersCallout = useMemo(() => {
    return false; // TODO 10/11/2021 Hide for now: onboarding updates will replace it

    // return (
    //   isDistrictActivePlan &&
    //   members?.length &&
    //   members.length === 1 &&
    //   !loading
    // );
  }, [
    // members, loading, isDistrictActivePlan
  ]);

  const upcomingMeetings :any = useMemo(() => {
    return meetings
      .filter(
        (m) =>
          moment(m.date).isBetween(
            moment(),
            moment().add(NUMBER_OF_MONTHS, "months"),
            "day",
            "[]"
          ) ||
          moment(m.end_date).isBetween(
            moment(),
            moment().add(NUMBER_OF_MONTHS, "months"),
            "day",
            "[]"
          )
      )
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  }, [meetings]);

  if (getPlansLoading && !activePlan) {
    return <Spinner intent="primary" />;
  }

  if (!activePlan) {
    return <TeamPlanWelcome />;
  }

  const handleMeetingClick = (meeting :any) => {
    // setSelectedMeeting(meeting);
    getTagButton(meeting);
  };

  const dateTile = (meeting:any)=>{
    const dateMoment = moment(meeting.date);
    const endDateMoment = moment(meeting.end_date);
    const formatDate = meeting.all_day_event ? "MM/DD" : "MM/DD h:mmA";

    let res: string = dateMoment.format(formatDate);

    if (!dateMoment.isSame(endDateMoment)) {
      const endDateFormat = dateMoment.diff(endDateMoment, "days")
        ? "MM/DD h:mmA"
        : "h:mmA";
      res += ` - ${endDateMoment.format(endDateFormat)}`;
    }

    return res;
  }

  return (
    <>
      <Helmet>
        <title>{intl.formatMessage({ id: "app.titles.dashboard" })}</title>
      </Helmet>
      <div className="flex">
       <div className="w-4/5">
       <div className="teampage_container dashboard-new">
      {/* <div className="teampage_container"> */}
        <WorkspaceHeader
          title={intl.formatMessage({
            id: "app.titles.dashboard",
          })}
        />

        {/* <hr className="mt-2 mb-4" /> */}
        <div>
          {/* {!isDistrictActivePlan ? (
            <DashboardWidget wsAvailableDict={wsAvailableDict} />
          ) : null} */}

          {showNoMembersCallout ? (
            <div className="mb-4">
              <Callout intent="primary" icon={null}>
                <div className="flex items-center">
                  <img
                    src={image}
                    style={{ width: 48, height: "auto" }}
                    alt="Alert"
                  />
                  <p className="text-xl font-style={{ fontSize: '24px' }}semibold ml-2">
                    {intl.formatMessage({
                      id: "app.teaming.dashboard.no-users",
                    })}
                  </p>
                  <div className="flex flex-grow justify-end">
                    <Button
                      intent="primary"
                      text={intl.formatMessage({
                        id: "app.titles.navigate-to-invite-user-page",
                      })}
                      title={intl.formatMessage({
                        id: "app.titles.navigate-to-invite-user-page",
                      })}
                      onClick={handleNavigateClick}
                    />
                  </div>
                </div>
              </Callout>
            </div>
          ) : null}

          {/* <div className="space-y-4 mb-4">
            <DistrictSetupWidget />
          </div> */}

          <div
            className="mb-4"
            data-tour="leadership-team-widget"
            data-cy="leadership-team-widget"
          >
            <UpcomingEventsCard isActiveDistrictPlan={isDistrictActivePlan} />
          </div>

          <div className={`grid grid-cols-1 gap-4`}>
            <div className="space-y-4">
              {/* {!isDistrictActivePlan && ( */}
                <SurveyAdministrationWidget
                  activeSurveysDeployment={[activeSurveysDeployment[0]]}
                  onMoveToCollectDataPage={handleMoveToCollectDataClick}
                  isDataWorkspaceLocked={
                    !wsAvailableDict[PlanWorkspaces.Data]?.available
                  }
                />
              {/* // )} */}

              {/* <div data-tour={"recent-sci-writing-widget"}>
                <RecentSCIWritingWidget forDashboard />
              </div> */}
            </div>

            <div>{!isDistrictActivePlan  && <StrategicPlanWidget />}</div>
          </div>
        </div>
      </div>

      <DashboardTour />
      </div>
         <div className="w-1/5 upcoming-event-section">
         <div>
            <div className="text-xl font-bold text-blue-600 mb-2 upcomint-title">
              <img src={evntImg} alt="event icon" className="" />
              <span>Upcoming Events</span></div>
            </div>

          
 
            {/* {selectedMeeting ? (
      // Render the UpcomingActivityTag component with the selected meeting
      <UpcomingActivityTag meeting={selectedMeeting} />
    ) : (
      loadings ? (
        <Spinner intent="primary" className="mt-2" />
      ) : upcomingMeetings.length ? (
        <div className="flex flex-col gap-2">
          {upcomingMeetings.slice(0, MAX_ITEMS).map((m: any) => (
            <div key={m.id} className={`meeating-details ${m.meeting_type === "task" ? "active" : ""}`}>
              <h5 className="font-bold text-blue-500">Meeting(s): {m.title}</h5>
              <p className="meeting-time">
                {moment(m.date).format("MM/DD h:mm A")}
              </p>
              {m.meeting_type === "task" ? (
                <a
                  href="#"
                  className="meeting-link font-bold text-blue-500 underline"
                  onClick={() => handleMeetingClick(m)}
                >
                  View Details
                </a>
              ) : (
                <a
                  href="#"
                  className="meeting-link font-bold text-blue-500 underline"
                  onClick={() => handleMeetingClick(m)}
                >
                  Go To Meeting
                </a>
              )}
            </div>
          ))}
          {!!upcomingMeetings.slice(MAX_ITEMS).length && (
            <div className="flex items-center justify-center">
              <Button
                intent={"primary"}
                text={intl.formatMessage({ id: "app.titles.view-all-events" })}
                onClick={() => {
                  history.push(
                    generatePath(AuthRoutes.YourPlanTeam, {
                      workspace: "team",
                      tabId: isDistrictActivePlan
                        ? UserNotesType.DCLT_SCI_CALENDAR
                        : UserNotesType.SCLT_SCI_CALENDAR,
                    } as any)
                  );
                }}
              />
            </div>
          )}
        </div>
      ) : (
        <div className="mt-4">
          <p className="text-blue-600 text-center">
            {intl.formatMessage({ id: "app.dashboard.no-events" })}
          </p>
        </div>
      )
    )} */}


<div className="flex flex-col gap-2">
      {loadings ? (
        <Spinner intent="primary" className="mt-2" />
      ) : upcomingMeetings.length ? (
        upcomingMeetings.slice(0, MAX_ITEMS).map((m: any) => (
        
          <div key={m.id} className={`meeating-details ${m.meeting_type === "task" ? "active" : ""}`}>
            <h5 className="font-bold text-blue-500" style={{ fontSize: '16px' }}>{m.short_title}</h5>

            <p className="meeting-time">
              {moment(m.date).format("MM/DD  h:mm A")} - {moment(m.end_date).format("MM/DD  h:mm A")}
            </p>
            <p className="meeting-time">
            
            </p>
            {getTagButton(m)}
          </div>
        ))
      ) : (
        <div className="mt-4">
          <p className="text-blue-600 text-center">
            {intl.formatMessage({ id: "app.dashboard.no-events" })}
          </p>
        </div>
      )}

      {!!upcomingMeetings.slice(MAX_ITEMS).length && (
        <div className="flex items-center justify-center">
          <Button
            intent={"primary"}
            text={intl.formatMessage({ id: "app.titles.view-all-events" })}
            onClick={() => {
              history.push(
                generatePath(AuthRoutes.YourPlanTeam, {
                  workspace: "team",
                  tabId: isDistrictActivePlan
                    ? UserNotesType.DCLT_SCI_CALENDAR
                    : UserNotesType.SCLT_SCI_CALENDAR,
                } as any)
              );
            }}
          />
        </div>
      )}

      {selectedMeeting && (
        <div className="selected-meeting-details mt-4">
          <UpcomingActivityTag meeting={selectedMeeting} />
        </div>
      )}
    </div>



{/* 
             <div className="meeating-details"> 
                    <h5 className="font-bold text-blue-500">Meeting(s): Analyze Data</h5> 
                    <p className="meeting-time">11/23 9:00 AM</p> 
                    <a href="#" className="meeting-link font-bold text-blue-500">Go To Meeting</a>
            </div>
            <div className="meeating-details active"> 
                    <h5 className="font-bold text-blue-500">Meeting(s): Analyze Data</h5> 
                    <p className="meeting-time">11/23 9:00 AM</p> 
                    <a href="#" className="meeting-link font-bold text-blue-500">Go To Meeting</a>
            </div>
            <div className="meeating-details"> 
                    <h5 className="font-bold text-blue-500">Meeting(s): Analyze Data</h5> 
                    <p className="meeting-time">11/23 9:00 AM</p> 
                    <a href="#" className="meeting-link font-bold text-blue-500">Go To Meeting</a>
            </div>  */}
            </div>
       </div> 
      
    </>
  );
};

export default TeamPlanDashboard;