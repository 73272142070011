import React, { useEffect, useMemo, useState } from "react";
import { HorizontalBar } from "react-chartjs-2";
import {
  ChartDataSet,
  DOMAIN_ANSWER_MIN_VALUE,
  StackedBarData,
  StackedBarDataset,
  SurveyDemographic,
} from "../../types";
import ChartDataLabels, { Context } from "chartjs-plugin-datalabels";
import { useIntl } from "react-intl";
import {
  formatChartDataLabel,
  getLightenDarkenColor,
  pickTextColorBasedOnBgColor,
} from "./utils";
import { showBookmarkDialog } from "../../store/survey-reports/actions";
import { useDispatch } from "react-redux";
import { ChartTooltipItem, ChartTooltipModel, ChartData } from "chart.js";
import useDiscrepancyPlugin from "../../helpers/hooks/charts/useDiscrepancyPlugin";
import CustomHorizontalBarTooltip from "./CustomHorizontalBarTooltip";
import { Icon } from "@blueprintjs/core";
import { getUrlParams } from "../../helpers/url-utils";
import { GROUP_BY_PARAM } from "../surveys/table/survey-report-table-head/SurveyReportTableHead";
import { useLocation } from "react-router";

type OwnProps = {
  index?: number;
  maxAnswerIndex?: number;
  dataSet: ChartDataSet;
  displayGroupedLabels?: boolean;
  domainId?: number;
  nonIdealStateMessage?: string;
  rowsWithDiscrepancy?: number[];
  color?: string;
  doubleDisaggregation?: boolean;
  questionId?: number;
  isDisaggregation?: boolean;
  demographic?: SurveyDemographic;
  inReportInsights?: boolean;
};

type Props = OwnProps;

const BAR_SIZE = 65;

const FONT_SIZE = 22;

const MIN_THRESHOLD_VALUE = 1;

const NUMBER_OF_UNIQUE_BAR_COLORS = 5;
const BAR_GRADIENT_COEFFICIENT = 15;

const MEAN_SCORE_FRACTION_DIGITS = 2;

const CHART_WIDTH_RESPONSIVENESS_FACTOR = 18;
const LABEL_STRING_RESPONSIVENESS_FACTOR = 4;
const MIN_BAR_VALUE_TO_SHOW_DATALABEL = 1.6;

const MAX_MEAN_VALUE = 4;

const OPATCITY_30_PERCENT_HEX = "48";
const HEX_COLOR_STRING_LENGTH = 7;

const HEIGHT_PERCENTAGE_OCCUPIED_BY_BAR = 0.9;

const IN_REPORT_INSIGHTS_FLAG_X_ADJUSTMENT = 5;
const IN_REPORT_INSIGHTS_FLAG_Y_ADJUSTMENT = 20;
const IN_REPORT_INSIGHTS_FLAG_WIDTH = 40;
const IN_REPORT_INSIGHTS_FLAG_HEIGHT = 40;
const IN_REPORT_INSIGHTS_THRESHOLD = 2.5;
const IN_REPORT_INSIGHTS_TWO_POINT_FIVE = 2.5;
const IN_REPORT_INSIGHTS_ONE = 1;
const IN_REPORT_INSIGHTS_FLAG_LEFT_MARGIN = 60;

const BACKGROUND_COLORS = [
  "#003f5c",
  "#a05195",
  "#d45087",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
];
const WHITE_COLOR_HEX = "#ffffff";
const BLACK_COLOR_HEX = "#000000";
const HorizontalBarChart: React.FC<Props> = (props: Props) => {
  const {
    index,
    maxAnswerIndex,
    dataSet,
    domainId,
    displayGroupedLabels,
    nonIdealStateMessage,
    rowsWithDiscrepancy,
    color,
    isDisaggregation,
    doubleDisaggregation,
    questionId,
    demographic,
    inReportInsights,
  } = props;

  const id = "valueHeader" + index + domainId;
  let header: HTMLElement | null = document.getElementById(id);

  useEffect(() => {
    header = document.getElementById(id);
    if (header) header.style.display = "none";
  }, []);

  const location = useLocation();

  const intl = useIntl();

  const dispatch = useDispatch();
  let chartReference = {};
  const barHeight = useMemo(() => {
    if (dataSet.labels?.length || dataSet.data?.length) {
      const numberOfBars = dataSet.labels.length || dataSet.data.length;
      return numberOfBars * BAR_SIZE;
    }
    return BAR_SIZE;
  }, [dataSet]);

  const gradientColors = useMemo(() => {
    if (!!domainId && !!displayGroupedLabels) {
      if (dataSet?.data || dataSet?.datasets) {
        return (dataSet.data || dataSet.datasets)?.map((item, i) => {
          return getLightenDarkenColor(
            color
              ? color
              : BACKGROUND_COLORS[domainId % BACKGROUND_COLORS.length],
            (i % NUMBER_OF_UNIQUE_BAR_COLORS) * BAR_GRADIENT_COEFFICIENT
          );
        });
      }
    }
  }, [props]);

  const data: any = useMemo(() => {
    const labels = dataSet.labels.map((label: string | string[]) => {
      let fullLabel = "";
      if (typeof label === "string") {
        fullLabel = label;
      } else {
        fullLabel = label.join("");
      }

      return fullLabel === ""
        ? intl.formatMessage({ id: "app.survey-report.charts.not-specified" })
        : fullLabel;
    });

    const datasetBgColor = color
      ? color
      : gradientColors && !doubleDisaggregation // gradient will only work if chart is normal bar chart
      ? gradientColors
      : BACKGROUND_COLORS[domainId! % BACKGROUND_COLORS.length];

    const commonDatalablesProperties = {
      name: {
        align: "end",
        anchor: "end",
        offset: doubleDisaggregation
          ? (ctx: Context) => {
              if (ctx.chart && ctx.chart.width) {
                return ctx.chart.width / 2 - 60;
              } else return 0;
            }
          : 60,
        clamp: true, //dynamically calculates anchor
        clip: true,
        font: {
          size: FONT_SIZE,
          lineHeight: 1.5,
          weight: "bold",
          family: `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        },
        formatter: formatChartDataLabel(nonIdealStateMessage),
      },
      value: {
        align: "left",
        anchor: "end",
        color: (context: Context) => {
          if (
            context.dataset.data &&
            context.dataset.data[context.dataIndex] === "1.04"
          )
            return BLACK_COLOR_HEX;
          if (typeof datasetBgColor == "string") {
            return pickTextColorBasedOnBgColor(
              datasetBgColor as any,
              WHITE_COLOR_HEX,
              BLACK_COLOR_HEX
            );
          } else {
            return WHITE_COLOR_HEX;
          }
        },
        font: {
          size: FONT_SIZE,
          lineHeight: 1.5,
          weight: doubleDisaggregation ? "normal" : "bold",
          family: `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        },
      },
    };

    if (!doubleDisaggregation) {
      return {
        labels: labels,
        datasets: [
          {
            backgroundColor: datasetBgColor,
            data: dataSet.data.map((number) =>
              number == null
                ? DOMAIN_ANSWER_MIN_VALUE
                : number.toFixed(MEAN_SCORE_FRACTION_DIGITS)
            ),
            barPercentage: HEIGHT_PERCENTAGE_OCCUPIED_BY_BAR,
            datalabels: {
              labels: {
                name: displayGroupedLabels
                  ? commonDatalablesProperties.name
                  : undefined,
                value: {
                  ...commonDatalablesProperties.value,
                  anchor: "end",
                  offset: function (context: Context) {
                    // if value is one then we are setting it as 1.04 to show thin bar; here now to show label outside we are using this static condition; in future we can try better solution
                    if (
                      context.dataset.data &&
                      context.dataset.data[context.dataIndex] === "1.04"
                    )
                      return -60;
                  },
                  formatter: function (value: string) {
                    // if value is one then we are setting it as 1.04 to show thin bar; here now to show actual value that is 1 we are again using a static condition
                    if (value === "1.04") value = "1.00";
                    return value + `${!!maxAnswerIndex ? "" : "%"}`;
                  },
                },
              },
            },
          },
        ],
      };
    } else {
      dataSet?.datasets?.forEach((ds: any, i: number) => {
        if (dataSet && dataSet.datasets) {
          const datasetsProperties = {
            backgroundColor: datasetBgColor,
            backgroundColor2: datasetBgColor, //this is to make original color always accessible in hover function
            barPercentage: HEIGHT_PERCENTAGE_OCCUPIED_BY_BAR,
            borderWidth: 1,
            borderColor: (context: Context) => {
              if (typeof datasetBgColor == "string") {
                return pickTextColorBasedOnBgColor(
                  datasetBgColor as any,
                  WHITE_COLOR_HEX,
                  BLACK_COLOR_HEX
                );
              } else {
                return WHITE_COLOR_HEX;
              }
            },
            datalabels: {
              labels: {
                name: displayGroupedLabels
                  ? commonDatalablesProperties.name
                  : undefined,
                value: {
                  ...commonDatalablesProperties.value,
                  formatter: function (value: number, ctx: Context) {
                    if (!!ctx.chart?.data?.labels && dataSet.datasets) {
                      const sortedData = dataSet.datasets
                        ?.map((j: StackedBarDataset) => j.data[ctx.dataIndex])
                        .sort(
                          (a: StackedBarData, b: StackedBarData) => a.x - b.x
                        );

                      let previousLabel = "";
                      for (let i = 0; i < sortedData.length; i++) {
                        let diff = sortedData[i]?.x;
                        if (diff && sortedData[i - 1] && sortedData[i - 1].x)
                          diff -= sortedData[i - 1].x;
                        // x!==MIN_THRESHOLD_VALUE; is because we want to hide label for value 1
                        if (
                          diff != null &&
                          sortedData[i]?.x !== MIN_THRESHOLD_VALUE
                        ) {
                          let indexToShow = dataSet.datasets
                            .map((k: StackedBarDataset) => k.data)
                            .map((l: StackedBarData[]) =>
                              l.find((y: StackedBarData) => y === sortedData[i])
                            )
                            .findIndex((v: any) => v !== undefined);

                          let label = dataSet.datasets[indexToShow][
                            "label"
                          ] as string;

                          let finalLabel = "";

                          if (
                            sortedData[i + 1] &&
                            sortedData[i + 1].x &&
                            sortedData[i].x === sortedData[i + 1].x &&
                            diff >= MIN_THRESHOLD_VALUE
                          ) {
                            previousLabel = label;
                            continue;
                          } else if (previousLabel != "") {
                            finalLabel =
                              ctx.datasetIndex == indexToShow
                                ? `${previousLabel}${
                                    previousLabel ? "," : ""
                                  } ${label}`
                                : "";
                            previousLabel = "";
                          } else if (diff >= MIN_THRESHOLD_VALUE) {
                            finalLabel = label =
                              ctx.datasetIndex == indexToShow ? label : "";
                          }
                          if (
                            ctx?.chart?.width &&
                            ctx.datasetIndex === indexToShow &&
                            finalLabel
                          ) {
                            let barWidth =
                              ((ctx?.chart?.width / MAX_MEAN_VALUE) *
                                sortedData[i].x) /
                              CHART_WIDTH_RESPONSIVENESS_FACTOR;
                            finalLabel =
                              barWidth > finalLabel.length
                                ? finalLabel
                                : finalLabel.substring(
                                    0,
                                    Math.floor(barWidth) -
                                      LABEL_STRING_RESPONSIVENESS_FACTOR
                                  ) + "...";
                          }

                          if (sortedData[i].x < MIN_BAR_VALUE_TO_SHOW_DATALABEL)
                            return "";
                          else return finalLabel;
                        }
                      }
                      return "";
                    }
                  },
                },
              },
            },
          };
          dataSet.datasets[i] = {
            ...dataSet.datasets[i],
            ...datasetsProperties,
          };
        }
      });
      return {
        labels: labels,
        datasets: dataSet?.datasets,
      };
    }
  }, [props]);

  const [tooltipModel, setTooltipModel] = useState<
    ChartTooltipModel | undefined
  >();
  const options: any = useMemo(
    () => ({
      // showLines: false,
      // segmentShowStroke: false,
      responsive: true,
      maintainAspectRatio: false,
      // zeroLineColor: "red",
      legend: {
        display: false,
      },
      tooltips: dataSet.data
        ? { enabled: false }
        : {
            enabled: false,
            mode: "point",
            custom: (tooltip: ChartTooltipModel) => {
              if (tooltip.opacity === 0) {
                setTooltipModel(undefined);
                return;
              }
              setTooltipModel(tooltip);
            },
            callbacks: {
              title: function (
                tooltipItem: ChartTooltipItem[],
                data: ChartData
              ) {
                return "";
              },
              label: function (tooltipItem: any, data: any) {
                if (tooltipItem != undefined) {
                  const sortedData = dataSet.datasets
                    ?.map((j: StackedBarDataset, i) => {
                      return {
                        ...j.data[tooltipItem.index],
                        ...{ datasetIndex: i },
                      };
                    })
                    .sort((a: StackedBarData, b: StackedBarData) => a.x - b.x);
                  const hoverIndex = sortedData?.findIndex(
                    (i: any) => tooltipItem.datasetIndex === i.datasetIndex
                  );
                  if (
                    hoverIndex != undefined &&
                    hoverIndex >= 0 &&
                    sortedData
                  ) {
                    if (data && data.datasets && tooltipItem) {
                      //if value of next group/block is same then we are showing both label in one tooltip
                      let nextlabel = "";
                      if (
                        sortedData[hoverIndex].x ===
                        sortedData[hoverIndex + 1]?.x
                      ) {
                        nextlabel =
                          ", " +
                          data.datasets[
                            sortedData[hoverIndex + 1].datasetIndex
                          ]["label"];
                      }

                      let tooltipItems = [
                        data.datasets[tooltipItem.datasetIndex]["label"] +
                          nextlabel +
                          ": " +
                          data.datasets?.[tooltipItem.datasetIndex]?.data[
                            tooltipItem.index
                          ]?.x?.toFixed(2),
                      ];
                      if (nextlabel)
                        tooltipItems.push(
                          intl.formatMessage({
                            id:
                              "app.survey-report.table.bar-disable-highlighting-msg",
                          })
                        );
                      return tooltipItems;
                    }
                  }
                }
              },
            },
          },
      scales: {
        xAxes: [
          {
            stacked: true,
            display: false,
            ticks: {
              min: 1,
              max: 4,
              stepSize: 1,
            },
            // gridLines: {
            //   drawOnChartArea: false,
            //   color: "black",
            //   zeroLineColor: "black",
            // },
          },
        ],
        yAxes: [
          {
            stacked: true,
            display: false,
          },
        ],
      },
      hover: {
        onHover: (e: MouseEvent, activeEls: any) => {
          if (inReportInsights && isDisaggregation) {
            let ox = e.offsetX;
            let oy = e.offsetY;
            let xAxis = (chartReference as any)?.chartInstance.scales[
              "x-axis-0"
            ];
            let yAxis = (chartReference as any)?.chartInstance.scales[
              "y-axis-0"
            ];

            for (let i = 0; i < data?.datasets[0]?.data.length; i++) {
              if (
                parseFloat(data?.datasets[0]?.data[i]) <
                  IN_REPORT_INSIGHTS_THRESHOLD &&
                parseFloat(data?.datasets[0]?.data[i]) > 1
              ) {
                let x =
                  (data?.datasets[0]?.data[i] < 1.4
                    ? xAxis.getPixelForValue(data?.datasets[0]?.data[i]) +
                      IN_REPORT_INSIGHTS_FLAG_LEFT_MARGIN
                    : xAxis.getPixelForValue(data?.datasets[0]?.data[i])) +
                  IN_REPORT_INSIGHTS_FLAG_X_ADJUSTMENT;

                let y =
                  yAxis.getPixelForValue(i) -
                  IN_REPORT_INSIGHTS_FLAG_Y_ADJUSTMENT;

                if (
                  x - IN_REPORT_INSIGHTS_FLAG_WIDTH < ox &&
                  ox < x + IN_REPORT_INSIGHTS_FLAG_WIDTH &&
                  y - IN_REPORT_INSIGHTS_FLAG_HEIGHT < oy &&
                  oy < y + IN_REPORT_INSIGHTS_FLAG_HEIGHT
                ) {
                  if (header) {
                    header.style.display = "none";
                    header.style.display = "block";
                    header.style.width = "330px";

                    header.style.left = x + "px";
                    header.style.top = y + "px";
                    header.innerHTML = `<div class="tooltip">
                        <span class="tooltiptext bp3-popover">
                        This mean is below a preset threshold of ${IN_REPORT_INSIGHTS_THRESHOLD}0, which may indicate a potential concern given the number of respondents who had unfavorable perceptions of the topic.</span>
                      </div>`;
                    break;
                  }
                } else {
                  if (header) header.style.display = "none";
                }
              }
            }
          }
          function resetHover() {
            setTimeout(() => {
              let chart = (chartReference as any)?.chartInstance?.chart;
              const datasetBackgroundColor =
                chart.config.data.datasets[0].backgroundColor2;
              chart.config.data.datasets.forEach((dataset: any, i: number) => {
                dataset.datalabels.display = true;
                dataset.borderColor = [];
                dataset.backgroundColor = [];
                dataset.data.forEach((data: any, di: number) => {
                  dataset.backgroundColor.push(datasetBackgroundColor);
                  if (typeof datasetBackgroundColor == "string") {
                    dataset.borderColor.push(
                      pickTextColorBasedOnBgColor(
                        datasetBackgroundColor as any,
                        WHITE_COLOR_HEX,
                        BLACK_COLOR_HEX
                      )
                    );
                  } else {
                    dataset.borderColor.push(WHITE_COLOR_HEX);
                  }
                });
              });
              chart.update({
                lazy: true,
              });
            }, 100);
          }
          if (doubleDisaggregation) {
            if (activeEls.length > 0 && activeEls[0]) {
              const chart = activeEls[0]?._chart;
              const hoveredElarr = chart.getDatasetAtEvent(e);
              const sortedData = activeEls
                ?.map((j: any) => {
                  return {
                    ...j._model,
                    ...{ datasetIndex: j._datasetIndex },
                  };
                })
                .sort((a: any, b: any) => a.value - b.value);
              const hoverIndex = sortedData?.findIndex(
                (i: any) => hoveredElarr[0]._datasetIndex === i.datasetIndex
              );
              //if value of hovered index is equal to next stack value then don't show highlighting
              if (
                sortedData[hoverIndex + 1] &&
                sortedData[hoverIndex].value == sortedData[hoverIndex + 1].value
              ) {
                resetHover();
                // return false;
              } else {
                const datasetBackgroundColor =
                  chart.config.data.datasets[0].backgroundColor2;
                chart.config.data.datasets.forEach(
                  (dataset: any, i: number) => {
                    const [boldColor, lightColor] = [
                      datasetBackgroundColor,
                      datasetBackgroundColor + OPATCITY_30_PERCENT_HEX,
                    ];
                    dataset.backgroundColor = [];
                    dataset.borderColor = [];
                    dataset.datalabels.display = false;
                    dataset.datalabels.borderWidth = 0;

                    dataset.data.forEach((data: any, di: number) => {
                      const resultColor =
                        data.x <= hoveredElarr[di]?._model.value
                          ? boldColor
                          : lightColor;
                      dataset.backgroundColor.push(resultColor);
                      dataset.borderColor.push(resultColor);
                    });
                  }
                );
                chart.update({
                  lazy: true,
                });
              }
            } else {
              resetHover();
            }
          }
        },
      },
      onClick: function (evt: MouseEvent, element: any) {
        if (element.length > 0) {
          const selectedIndex = element[0]._index;
          if (
            dataSet.labelsWithDetail &&
            dataSet.labelsWithDetail[selectedIndex]
          ) {
            dispatch(
              showBookmarkDialog({
                bookmarkDetail: {
                  domainIndex: domainId,
                  questionId,
                  selectedLabelKey:
                    dataSet.labelsWithDetail[selectedIndex].original_label_key,
                  selectedLabelValue:
                    dataSet.labelsWithDetail[selectedIndex]
                      .original_label_value,
                },
              })
            );
          }
        }
      },
    }),
    [maxAnswerIndex, dataSet]
  );

  const discrepancyPlugin = useDiscrepancyPlugin(dataSet);

  const rerenderKey = useMemo(() => {
    let key: string = `chart_${barHeight}`;

    if (dataSet?.labelsWithDetail?.[0]?.original_label_key) {
      key += `_label_${dataSet.labelsWithDetail[0].original_label_key}`;
    }

    if (dataSet?.datasets?.[0]?.original_key) {
      key += `_dataset_${dataSet.datasets[0].original_key}`;
    }

    return key;
  }, [
    barHeight,
    dataSet?.labelsWithDetail?.[0]?.original_label_key,
    dataSet?.datasets?.[0]?.original_key,
  ]);

  const flagImagePlugin = useMemo(() => {
    return {
      id: "baravatar",
      afterDraw: (chart: any) => {
        let ctx = chart.chart.ctx;
        let xAxis = chart.scales["x-axis-0"];
        let yAxis = chart.scales["y-axis-0"];
        const image = new Image();
        image.src =
          "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADPElEQVRoge3WT2gTaRzG8ef3zmSSSdJdhdKge8hCQSRr2aNCvemxB08igghFiuDZBRE8LYKXFaGILC6CnqT+pXgRiYIabFGqrdvWbbYN1dZ0t9baNpPpm5n358VCbYxm2iQTIZ/jJHnn+ZI/BGjwl7b2Qmdn569dXV1nEonEllQq9dSPUV6ItRdisdiVjo6Ow/F4/Hx7e/suP0Z5oa+9MDw8bHV3dyObzXKhUMj7McqLoncgGo2eymQy8+l0+lp/f/8LP0Z5URQQj8dn29raNrUu5Hb2wtjuxygvigJWsFNomQI/uwvzNwaolqO8KBkAAC4QnoM6k0RoYgihQ7Ua5cVXA1Y44LgFXB5FaOgtwscYMKs9rFxlBaxQoB0FcPcszJlFmDclIocYkVi1xpWj6Ge0PNQE8D6G2qcAMEL/Qg8MqHD4lQhHx2AGxxEKTmJk4A0BbkUXr7HOgCKtTGgFAKURyNCBiAns3uOyEXgPov9A9D+Aadj5t8jbk8jl3kjLGjUm0yMEKL8DStEgRDN0vRlCAOLTJ1Yx4DJ0CDg/J6QtrXFHyufLkvvswvL1n/Jzr8u9gafvQJUYIG07k3YAmjgrAkYmE2n+eyy8+fQLoOVbL66HgM8wIBSJxDKJE9LcPPk4uKn3iRFNlHp+3QWsxkAwL9CR1fTnt0M/Xr0XKf7Fq+uAFQwE5gTtn2J95I75w8HVj1X7S7wRjs5qwmQaBngqQG5WKZKu4F/uBZu27V1e/AeoswAGlgS79w1HXQ/Z73q2Ata3XlMfAawGtYL7l5yfvljO6NX8DGBy1SNI9XtoeuLueg/xJ8B1+2BZx8XY4MONHlXbAMcZwlLuJPU97K3UkbUJcJxR2PYpepTsqfTR1Q1w3Qws+zRSyYsEcDVuUZ0A5ixJ+Qeixll6kHSqco9PKh0wDsc5RwF1gQafygqf/UWVCGAA94nEn7Qw00MLUJipwKll2khAGsBNAfcSQY5A2pXa5EnJANLEYhD4oIN1HSKng2YDwJgOpDQ4ySbIl7UcWkrJgKlYy40jL4eO1nLMenwXf6e/phHgt0aA3xoBfmsE+K0R4LdGgN+KApRS80R0C8CAD3savjsfAcKgJb5Vat8qAAAAAElFTkSuQmCC";
        ctx.save();
        if (inReportInsights && isDisaggregation) {
          for (let i = 0; i < data?.datasets[0]?.data.length; i++) {
            if (
              parseFloat(data?.datasets[0]?.data[i]) <
                IN_REPORT_INSIGHTS_TWO_POINT_FIVE &&
              parseFloat(data?.datasets[0]?.data[i]) > IN_REPORT_INSIGHTS_ONE
            ) {
              const xAxisValue =
                data?.datasets[0]?.data[i] < 1.4
                  ? xAxis.getPixelForValue(data?.datasets[0]?.data[i]) +
                    IN_REPORT_INSIGHTS_FLAG_LEFT_MARGIN
                  : xAxis.getPixelForValue(data?.datasets[0]?.data[i]);
              const yAxisValue = yAxis.getPixelForValue(i);
              ctx.drawImage(
                image,
                xAxisValue + IN_REPORT_INSIGHTS_FLAG_X_ADJUSTMENT,
                yAxisValue - IN_REPORT_INSIGHTS_FLAG_Y_ADJUSTMENT,
                IN_REPORT_INSIGHTS_FLAG_WIDTH,
                IN_REPORT_INSIGHTS_FLAG_HEIGHT
              );
            }
          }
        }
      },
    };
  }, [data?.datasets[0]?.data]);

  return (
    <>
      <div
        style={{ position: "relative" }}
        className="w-full"
        data-tour={"bar"}
      >
        <p id={id} style={{ position: "absolute" }}></p>
        <HorizontalBar
          key={rerenderKey}
          height={barHeight}
          data={data}
          options={options}
          plugins={[discrepancyPlugin, ChartDataLabels, flagImagePlugin]}
          ref={(reference) => {
            if (reference) chartReference = reference;
          }}
        />
        <CustomHorizontalBarTooltip
          datasets={dataSet?.datasets}
          labelsWithDetail={dataSet?.labelsWithDetail}
          domainId={domainId}
          questionId={questionId}
          demographic={demographic}
          tooltipModel={tooltipModel}
        />
      </div>
    </>
  );
};

export default HorizontalBarChart;
