import React, { FunctionComponent, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTeamMembersByPlan } from "../../../store/team-members/actions";
import { useLoading } from "../../../helpers/hooks/useLoading";
import { useIntl } from "react-intl";
import { isDistrictPlan } from "../../../store/plans/selectors";
import { InviteStatus } from "../../../types";
import { Callout, HTMLTable, Spinner, Card } from "@blueprintjs/core";
import { LeadersImg } from "../../../static/icons/icon-components/LeadersImg";

type OwnProps = {};

type Props = OwnProps;

const TeamTable: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const activePlan = useSelector((s) => s.plans.activePlan);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  // useEffect(() => {
  //   if (activePlan?.id) {
  //     dispatch(getTeamMembersByPlan.request({ planId: activePlan.id }));
  //   }
  // }, [activePlan?.id]);

  const districtSchoolTeamMembers = useSelector((s) => s.teamMembers.teamMembers);
  const members = useMemo(()=> {return districtSchoolTeamMembers.filter((dstm:any)=> dstm?.plan === activePlan?.id )},[districtSchoolTeamMembers,activePlan])
  
  const loading = useSelector(
    (s) => s.teamMembers.loading.getTeamMembersByPlan
  );
  const error = useSelector((s) => s.teamMembers.errors.getTeamMembersByPlan);
  useLoading({ error, loading });

  if (loading && !members.length) {
    return <Spinner intent="primary" />;
  }

  if (!members.length) {
    return (
      <Callout intent="warning">
        {intl.formatMessage({ id: "app.team-plan.team.no-other-members" })}
      </Callout>
    );
  }

  // return (
//     <HTMLTable className="w-full">
//       <div className={members.length >10?"dashboard-role-table":""}>
//         <thead>
//           <tr>
//             <th>{intl.formatMessage({ id: "app.titles.name" })}</th>
//             <th>
//               {intl.formatMessage({
//                 id: `app.titles.${
//                   isDistrictActivePlan ? "district-school-" : ""
//                 }role`,
//               })}
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {members.map((member) => {
//             const teamRole = intl.formatMessage({
//               id: `app.team-plan.team.roles.${member.team_role}`,
//             });
//             const userRole = intl.formatMessage(
//               { id: "app.roles" },
//               { role: member.user?.profile?.role }
//             );
//             return (
//               <>
//                 <tr key={member.user.id}>
//                   <td>
//                     {`${member.user.first_name} ${member.user.last_name}`}
//                     {member.user.is_active === false && member.invited_status === InviteStatus.Accepted ? (
//                       <i>
//                         {" "}
//                         {intl.formatMessage({ id: "app.titles.deactivated" })}
//                       </i>
//                     ) : (
//                       ""
//                     )}
//                   </td>
//                   <td className="italic">
//                     {member.user?.profile?.custom_role_name ?? userRole}
//                   </td>
//                 </tr>
//               </>
//             );
//           })}
//         </tbody>
//       </div>
//     </HTMLTable>
//   );
// };

const deactivatedMembers = members.filter((member) => {
  const isDeactivated = member.user.is_active === false && member.invited_status === InviteStatus.Accepted;
  return isDeactivated
});

return (
  <HTMLTable className="w-full">
<section>
    <div className="text-xl font-bold text-blue-600 mb-4">
      {/* District Climate Leadership Team */}
      {intl.formatMessage({
        id: `app.titles.${isDistrictActivePlan ? "district-" : ""}teaming`,
      })}
  </div>
  <Card className="leadershipTeamCard">
    <ul>
      <li><span className="card-circle">{members.length}</span>Members on the {`${isDistrictActivePlan ? 'DCLT' : 'SCLT'}`}</li>
      <li><span className="card-circle">{members.filter((member)=> member.invited_status === InviteStatus.Expired || member.invited_status === InviteStatus.Sent).length}</span>In Sent or Expired Status</li>
      <li><span className="card-circle">{deactivatedMembers.length}</span>Deactivated</li>
      <LeadersImg className="leaders-icon" customFillColor={`${isDistrictActivePlan ? '#255D8B' : '#2a035a'} `} />
      {/* <img src={leadrsImg} alt="leaders icon" className="leaders-icon" /> */}
    </ul>
  </Card>
</section>

  </HTMLTable>
);
};
export default TeamTable;