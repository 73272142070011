import React, { useEffect } from "react";
import { QuestionTag, SurveyDemographic } from "../../../../types";
import { getSurveyComparison } from "../../../../store/survey-reports/actions";
import { useDispatch, useSelector } from "react-redux";
import useComparison from "../useComparison";
import SurveyComparisonTable from "../../table/SurveyComparisonTable";

type OwnProps = {
  tag: QuestionTag;
  surveyDeploymentId?: number;
  schoolId?: number;
  availableDemographicsInSchool: SurveyDemographic[];

};

type Props = OwnProps;

const ComparisonInsightContent: React.FC<Props> = ({
  tag,
  surveyDeploymentId,
  schoolId,
  availableDemographicsInSchool
}: Props) => {
  const dispatch = useDispatch();

  const surveyComparison = useSelector((s) => s.surveyReports.surveyComparison);

  const { showNonIdealState, demographics } = useComparison({
    surveyComparison: surveyComparison,
  });

  useEffect(() => {
    if (tag && surveyDeploymentId && schoolId) {
      dispatch(
        getSurveyComparison.request({
          survey_deployment_id: [+surveyDeploymentId],
          school_id: [+schoolId],
          tag_id: tag.id,
        })
      );
    }
  }, [tag, surveyDeploymentId]);

  return (
    <SurveyComparisonTable
      surveyDeploymentId={surveyDeploymentId}
      tagId={String(tag.id)}
      demographics={availableDemographicsInSchool}
      surveyComparison={surveyComparison}
      showNonIdealState={showNonIdealState}
      domainId={undefined}
    />
  );
};

export default ComparisonInsightContent;
