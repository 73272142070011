import Axios from "axios";
import {
  District,
  SummaryStatistics,
  Survey,
  SurveyDeployment,
  SurveyDeploymentStatistics,
} from "../../types";
import { API_ROOT } from "../config";
import {
  AddSurveyDeploymentRequest,
  DownloadSampleLetterRequestType,
  UpdateSurveyDeploymentRequest,
} from "./types";

export const getSurveysDeployment = (
  planId?: number,
  isDeleted?: boolean
): Promise<SurveyDeployment<District, Survey<number>>[]> => {
  if (planId) {
    return Axios.get(
      `${API_ROOT}/surveys_deployments/plans/${planId}/${
        isDeleted ? `?is_deleted=${isDeleted}` : ""
      }`
    );
  }
  return Axios.get(`${API_ROOT}/surveys_deployments/`);
};

export const getAdminSurveysDeployment = (
  page?: number,
  page_size?: number
): Promise<SurveyDeployment<District, Survey<number>>[]> => {

  return Axios.get(`${API_ROOT}/surveys_deployments_admin/?page=${page}&page_size=${page_size}`);
};

export const addSurveyDeployment = (
  request: AddSurveyDeploymentRequest
): Promise<SurveyDeployment<District, Survey<number>>> => {
  return Axios.post(`${API_ROOT}/surveys_deployments/`, request);
};

export const updateSurveyDeployment = (
  surveyDeploymentId: number,
  request: UpdateSurveyDeploymentRequest
): Promise<SurveyDeployment<District, Survey<number>>> => {
  return Axios.put(
    `${API_ROOT}/surveys_deployments/${surveyDeploymentId}/`,
    request
  );
};

export const deleteSurveyDeployment = (
  surveyDeploymentId: number
): Promise<void> => {
  return Axios.delete(`${API_ROOT}/surveys_deployments/${surveyDeploymentId}/`);
};

export const getSurveysDeploymentStatistics = (
  schoolHash?: string
): Promise<SurveyDeploymentStatistics[]> => {
  return Axios.get(
    `${API_ROOT}/surveys_deployments/stats${
      schoolHash ? `?school=${schoolHash}` : ""
    }`
  );
};

export const getSummaryStatistics = (
  schoolHash?: string,
  surveyDeploymentId?: string
): Promise<SummaryStatistics[]> => {
  return Axios.get(
    `${API_ROOT}/surveys_deployments/summary_stats${
      schoolHash
        ? surveyDeploymentId
          ? `?school=${schoolHash}&survey_deployment_id=${surveyDeploymentId}`
          : `?school=${schoolHash}`
        : surveyDeploymentId
        ? `survey_deployment_id=${surveyDeploymentId}`
        : ""
    }`
  );
};

export const getSurveyDeploymentsBySchool = (
  schoolId: number
): Promise<SurveyDeployment<number, Survey<number>>[]> => {
  return Axios.get(`${API_ROOT}/schools/${schoolId}/survey_deployments`);
};

export const downloadSampleLetters = (
  surveyDeploymentId: number,
  planId: number,
  body: DownloadSampleLetterRequestType
): Promise<Blob> => {
  return Axios.post(
    `${API_ROOT}/survey_deployments/${surveyDeploymentId}/plans/${planId}/sample_letters/`,
    body,
    {
      responseType: "blob",
    }
  );
};

export const closeSurveyDeployment = (
  surveyDeploymentId: number,
  planId?: number
): Promise<SurveyDeployment<District, Survey<number>>> => {
  if (planId) {
    return Axios.post(
      `${API_ROOT}/surveys_deployments/${surveyDeploymentId}/plans/${planId}/`
    );
  } else {
    return Axios.post(
      `${API_ROOT}/surveys_deployments/${surveyDeploymentId}/finish/`
    );
  }
};

export const deleteSurveyDeploymentSoftly = (
  surveyDeploymentId: number
): Promise<void> => {
  return Axios.delete(
    `${API_ROOT}/surveys_deployments/${surveyDeploymentId}/soft_delete/`
  );
};
