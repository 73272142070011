import React from "react";
import { useIntl } from "react-intl";
import DashboardCard from "../DashboardCard";
import { District, Survey, SurveyDeployment } from "../../../../types";
import ActiveSurveysDeploymentList from "./ActiveSurveysDeploymentList";
import { Button, NonIdealState } from "@blueprintjs/core";

type OwnProps = {
  activeSurveysDeployment?: SurveyDeployment<District, Survey<number>>[];
  onMoveToCollectDataPage: () => void;
  isDataWorkspaceLocked: boolean;
};

type Props = OwnProps;

const SurveyAdministrationWidget: React.FC<Props> = ({
  activeSurveysDeployment,
  onMoveToCollectDataPage,
  isDataWorkspaceLocked,
}: Props) => {
  const intl = useIntl();

  return (
    <DashboardCard
      // title={intl.formatMessage({ id: "app.dashboard.survey-administration" })}
      body={<ActiveSurveysDeploymentList
            activeSurveysDeployment={activeSurveysDeployment}
          />
      }
      centeredBody
    />
  );
};

export default SurveyAdministrationWidget;