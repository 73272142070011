import React from "react";

type ColorProps = {
    className: string;
    customFillColor: any;
  };
  
type Props = ColorProps;

export const LeadersImg : React.FC<Props> = (props: Props) => {
    return (
      <svg
        width="60"
        height="35"
        className={props.className}
        viewBox="0 0 60 35"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M47.2362 30.816L47.117 30.4585C43.8997 24.3217 37.465 20.1511 30.0771 20.1511C22.6891 20.1511 16.2545 24.3217 13.0371 30.3989L12.918 30.7564C12.8584 30.8756 12.8584 31.0543 12.8584 31.233V32.7225C12.8584 33.6162 13.6329 34.3908 14.5266 34.3908H30.0771H45.6871C46.5808 34.3908 47.3553 33.6162 47.3553 32.7225V31.233C47.3553 31.1139 47.2957 30.9351 47.2362 30.816Z"
          fill={`${props.customFillColor || "#255D8B"}`} 
        />
        <path
          d="M10.1178 29.088L10.1774 28.9093C12.0244 25.4536 14.6459 22.6534 17.7441 20.6276C16.0759 19.8531 14.1693 19.436 12.2031 19.436C7.13883 19.436 2.67032 22.3555 0.46585 26.5857L0.34669 26.824C0.287109 26.9431 0.287109 27.0027 0.287109 27.1219V28.1347C0.287109 28.7901 0.823331 29.2668 1.41913 29.2668H9.99868L10.1178 29.088Z"
          fill={`${props.customFillColor || "#255D8B"}`} 
        />
        <path
          d="M12.2031 15.8019C15.2633 15.8019 17.744 13.3211 17.744 10.2609C17.744 7.20074 15.2633 4.71997 12.2031 4.71997C9.14288 4.71997 6.66211 7.20074 6.66211 10.2609C6.66211 13.3211 9.14288 15.8019 12.2031 15.8019Z"
          fill={`${props.customFillColor || "#255D8B"}`} 
        />
        <path
          d="M50.0374 29.0883L49.9778 28.9691C48.1308 25.5134 45.5093 22.7132 42.4111 20.6875C44.0794 19.9129 45.9859 19.4958 47.9521 19.4958C53.076 19.4958 57.4849 22.3557 59.749 26.5859L59.8681 26.8242C59.9277 26.9434 59.9277 27.0625 59.9277 27.1221V28.135C59.9277 28.7904 59.3915 29.267 58.7957 29.267H50.1566L50.0374 29.0883Z"
          fill={`${props.customFillColor || "#255D8B"}`} 
        />
        <path
          d="M47.9521 15.8019C51.0123 15.8019 53.493 13.3211 53.493 10.2609C53.493 7.20074 51.0123 4.71997 47.9521 4.71997C44.8919 4.71997 42.4111 7.20074 42.4111 10.2609C42.4111 13.3211 44.8919 15.8019 47.9521 15.8019Z"
          fill={`${props.customFillColor || "#255D8B"}`} 
        />
        <path
          d="M30.017 17.5893C34.7554 17.5893 38.5966 13.7481 38.5966 9.00972C38.5966 4.27137 34.7554 0.430176 30.017 0.430176C25.2787 0.430176 21.4375 4.27137 21.4375 9.00972C21.4375 13.7481 25.2787 17.5893 30.017 17.5893Z"
          fill={`${props.customFillColor || "#255D8B"}`} 
        />
      </svg>
    );
  };