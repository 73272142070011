import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Card, Icon, Spinner } from "@blueprintjs/core";
import TeamMembersTable from "../../team-members-table/TeamMembersTable";
import NoMembers from "../../no-members/NoMembers";
import UnderstandingRolesDialog from "../../understanding-roles-dialog/UnderstandingRolesDialog";
import InviteTeamMembersDialogContainer from "../../../../districts/dialogs/invite-users-dialog/InviteTeamMembersDialogContainer";
import { useDispatch, useSelector } from "react-redux";
import { useLoading } from "../../../../../helpers/hooks/useLoading";
import { useIntl } from "react-intl";
import {
  getTeamMembersByPlan,
  showLearnAboutSccpAndDcltdDialog,
  showUnderstandingRolesDialog,
} from "../../../../../store/team-members/actions";
import { showInviteUsersDialog } from "../../../../../store/users/actions";
import { getMeetings } from "../../../../../store/meetings/actions";
import UpcomingEvents from "../../../dashboard/upcoming-events/UpcomingEvents";
import SCIChangeProcess from "../../../../../static/images/School_Climate_Change_Process_Graphic.png";
import { isDistrictPlan } from "../../../../../store/plans/selectors";
import NextStepsHelper from "../../next-steps-helper/NextStepsHelper";
import FullScreenImageDialog from "../../full-screen-image-dialog/FullScreenImageDialog";
import RecommendationsToPrincipal from "../../next-steps-helper/RecommendationsToPrincipal";
import ManageUsersTour from "../../tours/ManageUsersTour";
import TeamHomeRecommendations from "./TeamHomeRecommendations";
import { generatePath, useHistory } from "react-router";
import DistrictClimateTeamDevelopmentTour from "../../tours/DistrictClimateTeamDevelopmentTour";
import UserEditingDialog from "../../../../districts/dialogs/UserEditingDialog";
import ManageUsersDialog from "../../manage-users-dialog/ManageUsersDialog";
import TeamDevelopmentGuidanceDialog from "../../team-development-guidance-dialog/TeamDevelopmentGuidanceDialog";
import RoleInfoDialog from "../../role-info-dialog/RoleInfoDialog";
import ReturnToDashboardCallout from "../../../../common/ReturnToDashboardCallout";
import useUserPlanAssociation from "../../../../../helpers/hooks/useUserPlanAssociation";
import { Activity, ActivityTypeEnum, DistrictSetupTaskKey, TeamMember } from "../../../../../types";
import { markStepCompleted } from "../../../../../store/tours/actions";
import useNextTourCallback from "../../../../../helpers/hooks/team-members/useNextTourCallback";
import RecentSCIWritingWidget from "../../../dashboard/recent-sci-writing-widget/RecentSCIWritingWidget";
import { ACTIVITY_LINK_CLASSNAME, useAttachActivities } from "../../../../../helpers/hooks/useAttachActivities";
import { showActivityVideoDialog } from "../../../../../store/activities/actions";
import GradeLevelSetup from "../../../dashboard/grade-level-dashboard-widget/GradeLevelSetup";
import useUserRole from "../../../../../helpers/hooks/useUserRole";
import { AuthRoutes } from "../../../../../App";
import Teamfilter from "../../Teamfilter/TeamFilter";
import MakingProgressTour from "../../../dashboard/district-setup-widget/tours/MakingProgressTour";

type OwnProps = {};

type Props = OwnProps;
const GUIDELINES_URL = "https://platform.njschoolclimate.org/files/activity/Mg==/download/pdf/Guidance+on+School+Climate+Leadership+Team+Development.pdf";

const TeamHomeTab: FunctionComponent<Props> = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();

  const activePlan = useSelector((s) => s.plans.activePlan);

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  useEffect(() => {
    if (activePlan?.id) {
      // dispatch(getTeamMembersByPlan.request({ planId: activePlan.id }));
      dispatch(getMeetings.request(activePlan.id));
    }
  }, [activePlan?.id]);

  const members = useSelector((s) => s.teamMembers.teamMembers);
  const [displayedTeamMembers, setDisplayedTeamMembers] = useState<
  TeamMember[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);
  const loading = useSelector(
    (s) => s.teamMembers.loading.getTeamMembersByPlan
  );
  const error = useSelector((s) => s.teamMembers.errors.getTeamMembersByPlan);
  useLoading({ error, loading });

  const isLastOnboardingTourWatched = useSelector(
    (s) => s.tours.districtSetupState?.lastTourWatched
  );

  const teamMemberFilter = (data: React.SetStateAction<TeamMember[]>) => {
    setDisplayedTeamMembers(data);
  };

  const loadings= (isLoader: boolean | undefined) => {
    setIsLoading(isLoader);
  }

  const { isDCC, isSuperintendent, user } = useUserRole();

  const hasUsersWithDcc = useMemo(() => {
    return displayedTeamMembers.some((m) => m.user?.profile?.is_dcc);
  }, [displayedTeamMembers]);

  const handleShowInviteUsersDialog = () => {
    dispatch(showInviteUsersDialog({}));
  };

  const handleImageClick = () => {
    dispatch(showLearnAboutSccpAndDcltdDialog(false));
  };

  const showWidgets = useMemo(() => {
    return !!activePlan;
  }, [activePlan]);

  const [showGuidanceDialog, setShowGuidanceDialog] = useState<boolean>(false);

  const handleTeamDevelopmentGuidanceClick = () => {
    setShowGuidanceDialog(true);
  };

  const [showRoleInfoDialog, setShowRoleInfoDialog] = useState<boolean>(false);

  const handleRoleInfoIconClick = () => {
    setShowRoleInfoDialog(true);
  };

  const showGuidanceButton = useMemo(() => {
    return !!activePlan?.school?.id;
  }, [activePlan?.school?.id]);

  const { planSchoolId } = useUserPlanAssociation();

  const { districtSetupState: tours, setupCompleted } = useSelector(
    (s) => s.tours
  );

  const showContinueCallout = useMemo(() => {
    if (!setupCompleted) {
      return planSchoolId
        ? tours[DistrictSetupTaskKey.InviteSchoolStaff]?.[planSchoolId]
          ?.softAddStaff
        : tours.softAddDistrictStaff;
    }
    return false;
  }, [planSchoolId, tours, setupCompleted]);


  useEffect(() => {
    if (!setupCompleted) {
      return () => {
        if (history.action === 'POP') {
            history.go(1);
        }
    };
    };
  }, [setupCompleted]);

  const onNext = useNextTourCallback(
    planSchoolId
      ? DistrictSetupTaskKey.InviteSchoolStaff
      : DistrictSetupTaskKey.InviteOtherMembers
  );

  const onNextTour = useNextTourCallback(
    DistrictSetupTaskKey.LearnAboutTeamDevelopment
  );

  const onContinueCalloutClick = () => {
    if (planSchoolId) {
      dispatch(
        markStepCompleted({
          key: DistrictSetupTaskKey.InviteSchoolStaff,
          schoolId: planSchoolId,
          softAdd: false,
        })
      );
      onNextTour && onNextTour();
    } else {
      dispatch(
        markStepCompleted({
          key: DistrictSetupTaskKey.InviteOtherMembers,
          softAdd: false,
        })
      );
      onNextTour && onNextTour();
    }
  };

  const infoButton = useCallback((onClick: Function) => {
    return (
      <Button
        icon={<Icon icon={"info-sign"} iconSize={22} color="black" />}
        onClick={() => onClick()}
        minimal
      />
    );
  }, []);

  const { onActivityClick } = useAttachActivities({
    onClick: (activity: Activity) => {
      switch (activity.activity_type) {
        case ActivityTypeEnum.KALTURA_VIDEO:
          dispatch(
            showActivityVideoDialog({
              activity: activity,
              viewable_field: "embed_code",
            })
          );
          break;

        default:
          break;
      }
    },
  });
  return (
    <div className="teampage_container__tab-content">
    <div className="teamFilter">
      <Teamfilter teamMemberFilter={teamMemberFilter} isLoader={loadings} />
      </div>
      {loading ? (
        <Spinner intent="primary" />
      ) : displayedTeamMembers.length ? (
        <div className="teamContent">
          <div>
            {activePlan && (
              <>
                {isDistrictActivePlan ? (
                  // <NextStepsHelper
                  //   hasUserWithDcc={hasUsersWithDcc}
                  //   activePlan={activePlan}
                  // />
                  <></>
                ) : (
                  <RecommendationsToPrincipal activePlan={activePlan} />
                )}
              </>
            )}
            <TeamMembersTable
              members={displayedTeamMembers}
              disableDccSelector={
                isDistrictActivePlan &&
                !hasUsersWithDcc &&
                !isLastOnboardingTourWatched
              }
              readonly
            />

            {/* {isDistrictActivePlan && (
              <div className="mt-4">
                <TeamHomeRecommendations />
              </div>
            )}
            <div className="mt-2">
              <RecentSCIWritingWidget />
            </div> */}
          </div>
            <div>
              {/* {!isDistrictActivePlan && (
                <Card>
                  <GradeLevelSetup
                    title={intl.formatMessage({
                      id: "app.titles.student-grade-level-setup",
                    })}
                  />
                </Card>
              )} */}
            {/* {showWidgets && (
              <Card>
                <UpcomingEvents
                  title={intl.formatMessage({
                    id: "app.titles.timeline-and-next-steps",
                  })}
                />
              </Card>
            )}
            <Card className="p-2 cursor-pointer" onClick={handleImageClick}>
              <div className="flex justify-center">
                <img
                  src={SCIChangeProcess}
                  alt="School Climate Improvement Change Process"
                  style={{ width: "30rem", minHeight: "278px" }}
                />
              </div>
            </Card> */}
           

            <DistrictClimateTeamDevelopmentTour />
          </div>
        </div>
      ) : (
        <NoMembers onClick={handleShowInviteUsersDialog} />
      )
      }

      <UnderstandingRolesDialog />
      <InviteTeamMembersDialogContainer activePlan={activePlan} />
      {/* <FullScreenImageDialog /> */}
      {(!isSuperintendent || isDCC) && <ManageUsersTour />}
      <UserEditingDialog />
      <ManageUsersDialog />
      <TeamDevelopmentGuidanceDialog
        show={showGuidanceDialog}
        onClose={() => setShowGuidanceDialog(false)}
      />
      <RoleInfoDialog
        show={showRoleInfoDialog}
        onClose={() => setShowRoleInfoDialog(false)}
      />
    </div >
  );
};

export default TeamHomeTab;