import React from "react";
import { useIntl } from "react-intl";
import DashboardCard from "./DashboardCard";
import { Divider } from "@blueprintjs/core";
import TeamTable from "./TeamTable";
import UpcomingEvents from "./upcoming-events/UpcomingEvents";
import FullScreenImageDialog from "../members/full-screen-image-dialog/FullScreenImageDialog";

type OwnProps = {
  isActiveDistrictPlan?: boolean;
};

type Props = OwnProps;

const UpcomingEventsCard: React.FC<Props> = ({
  isActiveDistrictPlan,
}: Props) => {
  const intl = useIntl();

  // return (
  //   <DashboardCard
  //     title={intl.formatMessage({
  //       id: `app.titles.${isActiveDistrictPlan ? "district-" : ""}teaming`,
  //     })}
  //     body={
  //       <div className="upcoming-events-card">
  //         <div>
  //           <TeamTable />
  //         </div>
  //         <Divider />
  //         <UpcomingEvents />
  //       </div>
  //     }
  //   />
  // );

  return (
    <DashboardCard
      // title={intl.formatMessage({
      //   id: `app.titles.${isActiveDistrictPlan ? "district-" : ""}teaming`,
      // })}
      body={
        <div className="upcoming-events-card">
          <div>
            <TeamTable />
          </div>
          {/* <Divider /> */}
          <UpcomingEvents />
           <FullScreenImageDialog/>
        </div>
      }
    />
  );
};

export default UpcomingEventsCard;