import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import { Meeting, MeetingType } from "../../types";
import { showMeetingDialog } from "../../store/meetings/actions";
import React, { useCallback } from "react";
import { Button } from "@blueprintjs/core";
import { getBackgroundColorByMeetingType } from "../../components/charts/utils";

export const useUpcomingActivityTag = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleMeetingClick = (meeting: Meeting) => {
    dispatch(showMeetingDialog({ meeting: meeting, viewOnly: true }));
  };

  const getTagBackgroundColor = useCallback((meeting: Meeting) => {
    return getBackgroundColorByMeetingType(meeting.meeting_type);
  }, []);

  const getTagButton = useCallback(
    (meeting: Meeting) => {
      
      return meeting.meeting_type === "task" ? (
          <a
            href="#"
            className="meeting-link font-bold text-blue-500 underline"
            onClick={() => handleMeetingClick(meeting)}
          >
            
             {intl.formatMessage ({id: "app.strategies.view-details"})}
          </a>
        ) : (
          <a
            href="#"
            className="meeting-link font-bold text-blue-500 underline"
            onClick={() => handleMeetingClick(meeting)}
          >
            {intl.formatMessage({id: "app.titles.go-to-meeting"})
             }
          </a>
        )
      
    },
    [handleMeetingClick]
  );

  return {
    getTagButton,
    getTagBackgroundColor,
  };
};

export default useUpcomingActivityTag;