import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { Button, Icon } from "@blueprintjs/core";
import DistrictComparisonTable from "../../survey-deployment/table/district-comparison-table/DistrictComparisonTable";
import DistrictComparisonInfoDialog from "./district-comparison-info-dialog/DistrictComparisonInfoDialog";
import { isDistrictPlan } from "../../../store/plans/selectors";
import SurveyDeploymentTableDialog from "../../survey-deployment/dialogs/SurveyDeploymentTableDialog";

type OwnProps = {
  DownloadButton?:React.ReactNode;
};

type Props = OwnProps;

const DistrictComparisonTab: React.FC<Props> = (props: Props) => {
  const intl = useIntl();

  const isDistrictActivePlan = useSelector(isDistrictPlan);

  const [showInfoDialog, setShowInfoDialog] = useState(false);

  const [showSurveyDeploymentDialog, setShowSurveyDeploymentDialog] = useState(
    false
  );

  const infoButton = useCallback(() => {
    return (
      <Button
        icon={<Icon icon={"info-sign"} iconSize={22} color="black" />}
        onClick={() => setShowInfoDialog(true)}
        minimal
      />
    );
  }, []);

  const onCloseInfoDialog = () => {
    setShowInfoDialog(false);
  };

  const showSurveyDeploymentTableDialog = (show: boolean) => {
    setShowSurveyDeploymentDialog(show);
  };

  return (
    <>
      <div className="comparisonTblScrollbar pb-5">
        <DistrictComparisonTable
          showSurveyDeploymentDialog={showSurveyDeploymentTableDialog}
          darkHeader
          isTeamPlanTable
          DownloadButton={props?.DownloadButton}
        />
      </div>
      <SurveyDeploymentTableDialog
        show={showSurveyDeploymentDialog}
        onClose={showSurveyDeploymentTableDialog}
      />
      <DistrictComparisonInfoDialog
        onClose={onCloseInfoDialog}
        show={showInfoDialog}
      />
    </>
  );
};

export default DistrictComparisonTab;