import React, { ReactNode } from "react";
import { Card } from "@blueprintjs/core";

type OwnProps = {
  title?: ReactNode;
  body: ReactNode;
  centeredBody?: boolean;
};

type Props = OwnProps;

const DashboardCard: React.FC<Props> = (props: Props) => {
  const { title, body, centeredBody } = props;
  
  return (
    // <Card
    //   className="p-0 flex flex-col"
    //   elevation={2}
    //   style={{ minHeight: "10rem" }}
    // >
    //   {title && (
    //     <>
    //       <div className="text-2xl font-bold text-blue-600 flex py-2 px-4">
    //         {title}
    //       </div>
    //       {/* <hr /> */}
    //     </>
    //   )}
    //   <div
    //     className={`py-2 px-4 ${
    //       centeredBody ? "flex flex-grow items-center" : ""
    //     }`}
    //   >
    //     {body}
    //   </div>
    // </Card>
    <section
      className="p-0 flex flex-col"
      style={{ minHeight: "10rem" }}
    >
      {title && (
        <>
          <div className="text-2xl font-bold text-blue-600 flex py-2 px-4">
            {title}
          </div>
          <hr />
        </>
      )}
      <div
        className={`py-2 px-4 ${
          centeredBody ? "flex flex-grow items-center" : ""
        }`}
        style={{display:"inline-block"}}
      >
        {body}
      </div>
    </section>
  );
};


export default DashboardCard;